import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'Styles/globals.scss'
import Layout from 'Layout/Layout';
import { loadableReady } from '@loadable/component';
import { SpeedInsights} from '@vercel/speed-insights/dist/react'

const preloadedState = window.__DS9_PRELOADED_STATE__;
const selectedFacetData = window.DS9_FACET_DATA;
// This is a SUPER simple check but generally fetch is a good catch all for the 3 big polyfills we pull in here.
//  fetch, async, and promises
// promises are handled in a nomodule script tag in _AppTemplate.cshtml which is not loaded in modern browsers just ie 11 !!!!
// Furthermore feel free to add more here as needed for ie but there is a pathway to do so now
main()

function main(){
    // Allow the passed state to be garbage-collected
    delete window.__DS9_PRELOADED_STATE__;
    delete window.DS9_FACET_DATA;

    let state = JSON.parse(preloadedState)
    state.selectedFacetData = selectedFacetData;

    let stateObjStr = JSON.stringify(state)

    loadableReady(() => {
        let app = document.getElementById('app');
        ReactDOM.hydrate(
        <>
                <SpeedInsights sampleRate={0.5}/>
        <Layout state={stateObjStr}/>
        </>, app);
    })
}
export * from 'Components';

